import React from "react"
import { PageProps } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/seo"
import componentStyles from "./brand-affinity.module.scss"
import Desktops from "../../assets/images/projects/brand-affinity/desktops.png"
import Scheme from "../../assets/images/projects/brand-affinity/scheme.png"
import LearnMoreSection from "../../components/LearnMoreSection/LearnMoreSection"
import SquareBlockList from "../../components/SquareBlockList/SquareBlockList"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const LoyaltyManager: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()

  //for adaptive images size
  const breakpoints = useBreakpoint();

  const bannerClasses = classNames(componentStyles.banner)

  const bannerContainerClasses = classNames(
    componentStyles.bannerContainer,
    componentStyles.container,
    componentStyles.LtrDirection,
    "container",
    "grid-2"
  )

  const sectionClasses = classNames(
    componentStyles.section,
    componentStyles.container,
    componentStyles.LtrDirection,
    "container"
  )

  const squareBlockList = [
    intl.formatMessage({
      id: "projects.brand-affinity.solution-description.paragraph-2-list.item-1",
    }),
    intl.formatMessage({
      id: "projects.brand-affinity.solution-description.paragraph-2-list.item-2",
    }),
    intl.formatMessage({
      id: "projects.brand-affinity.solution-description.paragraph-2-list.item-3",
    }),
  ]
  const liStylesForSquareBlockList = {
    maxWidth: '334px',
    width: 'auto',
    padding: 0
  }

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({
          id: "projects.brand-affinity.seo.description",
        })}
        title={intl.formatMessage({ id: "projects.brand-affinity.seo.title" })}
        pathname={location.pathname}
      />
      <section className={bannerClasses}>
        <div className={bannerContainerClasses}>
            <h1 className={componentStyles.bannerTitle}>
              {intl.formatMessage({
                  id: "projects.brand-affinity.banner.title",
                })}
            </h1>
        </div>
      </section>
      <section className={sectionClasses}>
        <div className={componentStyles.clientCompany}>
          <h2 className={componentStyles.title2}>
            {intl.formatMessage({
                  id: "projects.brand-affinity.client-company.title",
              })}
          </h2>
          <div className={componentStyles.text}>
            {intl.formatMessage({
                  id: "projects.brand-affinity.client-company.text",
              })}
          </div>
        </div>
      </section>
      <section className={sectionClasses}>
        <h2 className={componentStyles.title2}>
          {intl.formatMessage({
              id: "projects.brand-affinity.challenge.title",
            })}
        </h2>
        <div className={componentStyles.challengeBlock}>
              <div className={componentStyles.text}>
                {intl.formatMessage({
                  id: "projects.brand-affinity.challenge.paragraph-1",
                  })}
              </div>
        </div>
      </section>
      <section className={sectionClasses}>
        <h2 className={componentStyles.title2}>
          {intl.formatMessage({
            id: "projects.brand-affinity.solution-description.title",
            })}
        </h2>
        <div className={ componentStyles.solutionDescriptionBlock}>
            <div className={componentStyles.text + ' ' + componentStyles.item1}>
              {intl.formatMessage({
                id: "projects.brand-affinity.solution-description.paragraph-1.text-before-link",
                })}
              <a href="https://consultdragoman.com/" target="_blank">
                {intl.formatMessage({
                  id: "projects.brand-affinity.solution-description.paragraph-1.link",
                  })}
              </a>
              {intl.formatMessage({
                id: "projects.brand-affinity.solution-description.paragraph-1.text-after-link",
                })}
            </div>
          <img
            className={componentStyles.item2}
            src={Desktops}
            alt="Illustration with desktops"
            width={breakpoints.mediaBefore800 ? '288' : '367'}
            height={breakpoints.mediaBefore800 ? '236' : '296'}
          />
          <div className={componentStyles.item3}>
            <div className={componentStyles.bold}>
              {intl.formatMessage({
                id: "projects.brand-affinity.solution-description.paragraph-2",
                })}
            </div>
            <SquareBlockList itemList={squareBlockList} liStyles={liStylesForSquareBlockList} backgroundColor={'none'}/>
          </div>
        </div>
      </section>
      <section className={sectionClasses}>
          <h2 className={componentStyles.title2}>
            {intl.formatMessage({
              id: "projects.brand-affinity.how-it-works.title",
              })}
          </h2>
        <div className={ componentStyles.howItWorksBlock}>
          <div className={componentStyles.item1}>
            <div>
              {intl.formatMessage({
                id: "projects.brand-affinity.how-it-works.item1",
                })}
            </div>
            <div>
              {intl.formatMessage({
                id: "projects.brand-affinity.how-it-works.item2",
                })}
            </div>
            <div>
              {intl.formatMessage({
                id: "projects.brand-affinity.how-it-works.item3",
                })}
            </div>
          </div>
          <img
            className={componentStyles.item2}
            src={Scheme}
            alt="Illustration with scheme"
            width={breakpoints.mediaBefore800 ? '287' : '677'}
            height={breakpoints.mediaBefore800 ? '208' : '492'}
          />
        </div>
      </section>
      <section className={sectionClasses}>
        <div className={componentStyles.resultsBlocks}>
          <div>
            <h2 className={componentStyles.title2}>
              {intl.formatMessage({
                id: "projects.brand-affinity.results.title",
              })}
            </h2>
          <ul className={componentStyles.tickList}>
            <li className={componentStyles.item1 + ' ' + componentStyles.tickListItem}>
                {intl.formatMessage({
                  id: "projects.brand-affinity.results.paragraph-1",
                })}
            </li>
            <li className={componentStyles.item1 + ' ' + componentStyles.tickListItem}>
                {intl.formatMessage({
                  id: "projects.brand-affinity.results.paragraph-2",
                })}
            </li>
            <li className={componentStyles.item1 + ' ' + componentStyles.tickListItem}>
                {intl.formatMessage({
                  id: "projects.brand-affinity.results.paragraph-3",
                })}
            </li>
          </ul>
          </div>
          <div className={componentStyles.wrapper}>
            <div className={componentStyles.item2 + ' ' + componentStyles.benefitsBlock}>
              <div className={componentStyles.title}>
                {intl.formatMessage({
                    id: "projects.brand-affinity.benefits.title",
                  })}
              </div>
              <img
                src="/icons/diamond.svg"
                alt="Diamond image"
                width="44.69"
                height="47.69"
                className={componentStyles.dimondIcon}
              />
              <div className={componentStyles.digitBlock}>
                <div>
                  <div className={componentStyles.digit}>
                    50<span className={componentStyles.percent}>%</span>
                  </div>
                  {intl.formatMessage({
                      id: "projects.brand-affinity.benefits.list.item1",
                    })}
                </div>
                <div>
                  <div className={componentStyles.digit}>
                    32<span className={componentStyles.percent}>%</span>
                  </div>
                  {intl.formatMessage({
                      id: "projects.brand-affinity.benefits.list.item2",
                    })}
                </div>
                <div>
                  <div className={componentStyles.digit}>
                    +60<span className={componentStyles.percent}>%</span>
                  </div>
                  {intl.formatMessage({
                      id: "projects.brand-affinity.benefits.list.item3",
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LearnMoreSection/>
    </Layout>
  )
}
export default LoyaltyManager
